window.availableTranslations = {
  "general": {
    "beforeUnload": "Are you sure you want to leave this page?  You will lose any unsaved data.",
    "saving": "Saving...",
    "savedAt": "Saved at: ",
    "tryAgain": "Please try again.",
    "loadedAt": "Loaded at: ",
    "errorSaving": "Error Saving",
    "loading": "Loading content...",
    "save": "Save",
    "close": "Cancel",
    "ok": "Confirm"
  },
  "guidedEssay": {
    "copyBtnWarn": "You already have words in your text box. If you would like to add the sentence starter, just paste it inside the box.",
    "buildBtnWarn": "You already have words in your paragraph text box. If you would like to add the above text box contents, just paste here.",
    "buildEssay": "Build Essay"
  },
  "daForm": {
    "Doc Analysis": "Doc Analysis",
    "Doc Analysis Tooltip": "Document Analysis",
    "Document Analysis Questions": "Document Analysis Questions",
    "Document number or letter": "Document number or letter",
    "Title of Document (if present)": "Title of Document (if present)",
    "Date of Document": "Date of Document",
    "Author of the Document": "Author of the Document",
    "Source: (Primary or Secondary)": "Source: (Primary or Secondary)",
    "Source (Where did the document come from?)": "Source (Where did the document come from?)",
    "Possible Author Bias / Point of View": "Possible Author Bias / Point of View",
    "Close Reading of the Document": "Close Reading of the Document",
    "Additional Input Fields": '<strong>NOTE:</strong> Additional input fields can be <span class=\"legend-mockLink\"><svg class=\"icon\" aria-hidden=\"true\"><use href=\"/static/svg/custom-icons.svg#plus-circle\"></use></svg></span> added or <span class=\"legend-mockLink\"><svg class=\"icon\" aria-hidden=\"true\"><use href=\"/static/svg/custom-icons.svg#minus-in-circle\"></use></svg></span> removed from this section.',
    "NOTE: Additional input fields can be added or removed from this section.": "NOTE: Additional input fields can be added or removed from this section.",
    "What important details are included in the document?": "What important details are included in the document?",
    "What inference can you make based on these details?": "What inference can you make based on these details?",
    "Input": "Input",
    "How does this document help answer the question?": "How does this document help answer the question?",
    "Overall, what is the main idea of the document?": "Overall, what is the main idea of the document?",
    "Analytical category (bucket)": "Analytical category (bucket)",
    "Analytical category / bucket": "Analytical category / bucket",
    "Sourcing the Document": "Sourcing the Document",
    "Type of document (e.g. letter, diary, etc.)": "Type of document (e.g. letter, diary, etc.)",
    "Important details about the era": "Important details about the era",
    "Important details about the author (e.g. title, gender, nationality, etc.)": "Important details about the author (e.g. title, gender, nationality, etc.)",
    "Are these facts, opinions, or a mixture of both?": "Are these facts, opinions, or a mixture of both?",
    "Document Summary": "Document Summary",
    "Document Evaluation and Interrogation": "Document Evaluation and Interrogation",
    "Is this document reliable? What makes it reliable/not reliable?": "Is this document reliable? What makes it reliable/not reliable?",
    "What further questions of investigation does this document raise?": "What further questions of investigation does this document raise?",
    "Argument Made from the Document": "Argument Made from the Document",
    "How does this document help you answer the driving question?": "How does this document help you answer the driving question?",
    "Cross-referencing and Bucketing of the Document": "Cross-referencing and Bucketing of the Document",
    "What other documents support your findings from this document?": "What other documents support your findings from this document?",
    "Do any documents conflict with your findings from this document?": "Do any documents conflict with your findings from this document?",
    "Mark As Complete": "Mark As Complete",
    "Completed": "Completed",
    "Key quote, image or data": "Key quote, image or data",
    'Main Idea of the Document': 'Main Idea of the Document',
    'Highly Scaffolded Form': 'Highly Scaffolded Form',
    'Facts/Observations: What important information can I learn from this document?': 'Facts/Observations: What important information can I learn from this document?',
    'Write down one direct quote or observation from the document that gives you information that helps answer the DBQ question. If a quote, use quotation marks and include citation, e.g. (Doc. A).': 'Write down one direct quote or observation from the document that gives you information that helps answer the DBQ question. If a quote, use quotation marks and include citation, e.g. (Doc. A).',
    'Put the meaning of the quotation in your own words. Your response should be at least one full sentence. Remember to include citation.': 'Put the meaning of the quotation in your own words. Your response should be at least one full sentence. Remember to include citation.',
    'Inferences: What inference can I make from the fact above?': 'Inferences: What inference can I make from the fact above?',
    'From the fact or observation above, what inference can you make? Your response should be at least one <b>full sentence</b>.': 'From the fact or observation above, what inference can you make? Your response should be at least one <b>full sentence</b>.',
    'Argument: How does this document help answer the question?': 'Argument: How does this document help answer the question?',
    'From the fact or observation above, how does this information help you answer the DBQ question? Your response should be at least one <b>full sentence</b>.': 'From the fact or observation above, how does this information help you answer the DBQ question? Your response should be at least one <b>full sentence</b>',
    'Bucket: In what analytical bucket does this fact or observation belong?': 'Bucket: In what analytical bucket does this fact or observation belong?',
    'Write your label here': 'Write your label here',
    'Long Form 1': 'Long Form 1',
    'Title of Document': 'Title of Document',
    'Date of publication': 'Date of publication',
    'Genre / Document type (e.g. poem, novel, photo)': 'Genre / Document type (e.g. poem, novel, photo)',
    'Historical context (if relevant)': 'Historical context (if relevant)',
    'What key details or quotations are included in the document?': 'What key details or quotations are included in the document?',
    'Based on these details/quotes, what inference(s) can you make?': 'Based on these details/quotes, what inference(s) can you make?',
    'Relationship to Other Documents': 'Relationship to Other Documents',
    'Are there other documents which in some way support or conflict with the ideas presented in this document?': 'Are there other documents which in some way support or conflict with the ideas presented in this document?',
    'How does this document help you answer the central question of this Mini-Q?': 'How does this document help you answer the central question of this Mini-Q?',
    "Last Input": "Last Input",
    "Evidence: What important information can I learn from this document?":  "Evidence: What important information can I learn from this document?",
    "Write down one piece of evidence (quote, fact or observation) that helps answer the Mini-Q question." : "Write down one piece of evidence (quote, fact or observation) that helps answer the Mini-Q question.",
    "Inference: What inference can I make from the evidence above?" : "Inference: What inference can I make from the evidence above?",
    "Explain one inference that could be made from the document in at least one full sentence" :  "Explain one inference that could be made from the document in at least one full sentence",
    "Develop your argument here in at least one full sentence.":  "Develop your argument here in at least one full sentence.",
    "Bucket: In what bucket does this evidence belong?":  "Bucket: In what bucket does this evidence belong?"
  },
  "litLongForm": {
    "documentAnalysisSheet": "Document Analysis Sheet: Long Form I",
    "docNumberLetter": "Document number or letter",
    "docTitle": "Title of Document",
    "pubDate": "Date of publication",
    "docAuthor": "Author of the Document",
    "docGenre": "Genre / Document type  (e.g. poem, novel, photo)",
    "docSource": "Source (Where did the document come from?)",
    "keyDetailsQuotes": "What key details or quotations are included in the document?",
    "userInferenceQuestion": "Based on these details/quotes, what inference(s) can you make?",
    "historicalContext": "Historical context (if relevant)",
    "closeReadingOfDocument": "Close Reading of the Document",
    "otherDocsRelationship": "Relationship to Other Documents",
    "docConflict": "Are there other documents which in some way support or conflict with the ideas presented in this document?",
    "docArgument": "Argument Made from the Document",
    "docBucket": "Analytical category (bucket)",
    "docCentralQuestionMiniQ": "How does this document help you answer the central question of this Mini-Q?"
  },
  "longForm1": {
    "DbqDocAnalysisSheet": "Document Analysis Sheet: Long Form I",
    "docNumberLetter": "Document number or letter",
    "docTitle": "Title of Document (if present)",
    "docDate": "Date of Document",
    "docAuthor": "Author of the Document",
    "docPSsource": "Source: (Primary or Secondary)",
    "docSource": "Source (Where did the document come from?)",
    "docBias": "Possible Author Bias / Point of View",
    "docColumnInstruction": "After you read over the document, fill out the columns below.",
    "docImportantDetails": "What important details are included in the document?",
    "userInferenceQuestion": "What inference can you make based on these details?",
    "docHelp": "How does this document help answer the question?",
    "docMainIdea": "Overall, what is the main idea of the document?",
    "docBucket": "Analytical category (bucket)"
  },
  "longForm2": {
    "docAnalysisSheet": "Document Analysis Sheet: Long Form II",
    "sourcingTheDocument": "Sourcing the Document",
    "docNumberLetter": "Document number or letter",
    "docTitle": "Title of Document (if present)",
    "docSource": "Source (Where did the document come from?)",
    "docType": "Type of document (e.g. letter, diary, etc.)",
    "docDate": "Date of Document",
    "docAuthor": "Author of the Document",
    "docPSsource": "Source: (Primary or Secondary)",
    "docEra": "Important details about the era",
    "docAuthorDetails": "Important details about the author (e.g. title, gender, nationality, etc.)",
    "docBias": "How might the author’s point of view affect the content and message of the document?",
    "closeReadingOfDocument": "Close Reading of the Document",
    "daCompareQues1": "What important details are included in the document?",
    "daCompareQues2": "Are these facts, opinions, or a mixture of both?",
    "daCompareQues3": "What inference can you make based on these details?",
    "facts": "Facts",
    "opinions": "Opinions",
    "both": "Both",
    "docSummary": "Document Summary",
    "docMainIdea": "Overall, what is the main idea of the document?",
    "docEvaluation": "Document Evaluation and Interrogation",
    "docReliable": "Is this document reliable? What makes it reliable/not reliable?",
    "docFurther": "What further questions of investigation does this document raise?",
    "docArgument": "Argument Made from the Document",
    "docHow": "How does this document help you answer the driving question?",
    "docCRbucketing": "Cross-referencing and Bucketing of the Document",
    "docWhat": "What other documents support your findings from this document?",
    "docConflict": "Do any documents conflict with your findings from this document?",
    "docBucket": "Analytical category (bucket)"
  },
  "ebNavLinks": {
    "bucketing": "Bucketing",
    "bucketingTruncated": "Bucketing",
    "unpackingBuckets": "Unpacking the Buckets",
    "unpackingBucketsTruncated": "Unpacking",
    "guidedEssay": "Guided Essay",
    "essayOutline": "Essay Outline",
    "essayOutlineTruncated": "Outline",
    "thrashout": "Thrash-out",
    "thrashoutTruncated": "Thrash-out"
  },
  "ebBucketing": {
    "gettingReadyToWrite": "Bucketing - Getting Ready to Write",
    "bucketing": "Bucketing",
    "reviewDocsInstructions": "Review the documents and organize them into your final buckets. Drag the letters of the documents into the buckets, then type in the bucket labels. Feel free to add or delete a bucket. It is OK to put a document in more than one bucket. Remember, your buckets are going to become your body paragraphs.",
    "addBucket": "Add Bucket",
    "thesisDevRoadMap": "Thesis Development and Road Map",
    "chkFtInstructions": "On the chickenfoot below, write your thesis and road map. Your thesis is what you are going to argue in the paper, and it must answer the DBQ or Mini-Q question. The road map is pre-loaded from your bucket labels and lists the topic areas you will examine in order to prove your thesis. If you would like to change the order of your road map, drag the “toe” of the chickenfoot to a new position. This also changes the order of your buckets. If you would like to change the words in your road map, you may do so by changing your bucket labels.",
    "maxBuckets": "You can not have more than 9 buckets",
    "delBucket": "Delete Bucket?",
    "delDoc": "Delete Document?",
    "rmDocTitle": "Delete Document",
    "rmBucketTitle": "Delete Bucket",
    "labelBucket": "Bucket Label",
  },
  "EbNestedSidebar": {
    "docAnalysisSheet": "DOC ANALYSIS Sheets:",
    "referenceLinks": "Reference Links",
    "documents": "Documents",
    "show": "Show",
    "hide": "Hide"
  },
  "ebOutline": {
    "essayOutline": "Essay Outline",
    "paperOutlineInstructions": "Now it is time to outline your paper. You can add or delete paragraphs and change the names of the elements in the paper. For example, “baby thesis” can become \"topic sentence\" and thesis can be changed to \"claim\".",
    "tabSaveWarning": "Saving issues will arise if you have two tabs open within DBQ Online.",
    "outlineToEssay": "Outline to Essay",
    "blueBoxInstructions": "In the blue boxes below, develop your ideas from your outline into a coherent paragraph for each section. Once you have finished writing your paragraphs, click Build Essay at the bottom so you can make edits and revise your final paper.",
    "introParagraph": "Introductory Paragraph",
    "edit": "Edit",
    "addNewField": "Add New Field",
    "writeYourIntro": "Write Your Introduction",
    "bodyParagraph": "Body Paragraph",
    "writeBodyParagraph": "Write Your Body Paragraph",
    "addNewParagraph": "Add New Paragraph",
    "concludingParagraph": "Concluding Paragraph",
    "studentEssayPage": "Move your paragraphs in blue to the Student Essay page where you can make edits and revise your essay.",
    "buildEssay": "Build Essay",
    "delField": "Delete Field",
    "conclusionPlaceholder": "Conclusion: Restatement of your thesis along with an insight that clinches your argument.",
    "delIntro": "Delete Introduction",
    "restoreIntro": "Restore Introduction",
    "delPara": "Delete Paragraph",
    "delConclusion": "Delete Conclusion",
    "restoreConclusion": "Restore Conclusion",
    "delFieldModal": "Delete Field?",
    "delParagraphModal": "Delete Paragraph?",
    "babyThesis": "Baby Thesis/Sub-claim",
    "evidence": "Evidence",
    "addEvidence": "Add Evidence",
    "delEvidence": "Delete Evidence",
    "addNewBodyParagraph": "Add Body Paragraph",
    "addCustomDirections": "Add Directions",
    "buildParagraph": "Build Paragraph",
    "buildEssayTitle": "Let’s build the essay!",
    "concludingSubtitle": "Write the Paragraph",
    "argument": "Argument",
    "newField": "New Field",
    "copyEvidence": "Copy",
    "copiedEvidence": "Copied!"
  },
  "ebPreoutline": {
    "unpackingBuckets": "Unpacking the Buckets / Extracting the Evidence",
    "bucketChkFtInstructions": "In the boxes below, list significant evidence for each bucket: key quotes, paraphrased ideas, data, image descriptions, and concrete detail. Feel free to cite your evidence using parenthetical citation like (Doc A). To add or delete a paragraph, go back to the Bucket and Chickenfoot page and add or delete a bucket.",
    "prePlaceholder": "Evidence..."
  },
  "ebThrashout": {
    "thrashout": "Thrash-Out",
    "thrashoutSides": "Creating Thrash-Out Sides",
    "thrashoutInstructions": "Before you sit down to write your essay, you and your classmates will “Thrash-out” your arguments in an informal debate. The class will be divided into teams. Each team will represent a different bucket.",
    "createTenativeBuckets": "Remember that you are creating tentative buckets at this point. Based on your understanding of the question and your reading of the documents, what might those buckets be? Remember, these may not be the final bucket labels that flow into your paper. You may create or delete buckets as you decide how many different sides will be represented in the Thrash-out.",
    "addBucket": "Add Bucket",
    "preThrashOut": "Pre-Thrash-Out",
    "reviewEvidenceQuestion": "Review all the evidence from the documents and decide which documents best support your team’s position. Write the document letters inside the bucket and in the space below use your selected documents to develop arguments in support of your team’s position. Decide also on 3-4 concrete details, quotes, or pieces of data that you want to bring into the debate and use to support your side.",
    "postThrashNotes": "During Thrash-Out | Post Thrash Notes",
    "collectEvidenceQuestion": "Either during or just after the Thrash-Out, collect the best arguments made by your team and from the other team(s). Fill the buckets with the documents they cite, and in the space below, paraphrase your team’s and your opposing team’s arguments. If possible, list the specific evidence used by all sides.",
    "prePlaceholder": "Type here..."
  },
  "annotationBase": {
    "feedback": "Feedback",
    "copy": "copy",
    "toggleMenu": "Toggle Options Menu",
    "clickEdit": "Click to edit"
  },
  "swBlockingError": {
    "weMissedYou": "We’ve missed you.",
    "sessionTimeOut": "Your session has automatically timed-out to protect your work.",
    "refreshInstruction": "Please press the REFRESH button in your browser to reconnect. Thanks.",
    "ohNo": "Oh no!",
    "noInternetConnection": "It seems you are no longer connected to the internet.",
    "checkConnection": "Please check your connection and try again."
  },
  "swEssayFeedback": {
    "awaitingTeacherComments": "Awaiting Teacher Comments",
    "sendToTeacher": "Send to Teacher",
    "sendToTeacher2": "Send to Teacher",
    "teacherReviewTitle": "Save and Submit",
    "teacherReview": "This will save your essay and submit it to your teacher for review."
  },
  "rubric": {
    "comprehensiveLit": {
      "DBQProjectRubric": "Comprehensive DBQ Project Rubric",
      "advanced": "Advanced",
      "proficient": "Proficient",
      "emerging": "Emerging",
      "missing": "Not Yet or Missing",
      "intro": "Introduction",
      "grabber": "Grabber",
      "creativeEyeCatching": "Creative, eye-catching, relevant",
      "relevantClear": "Relevant, clear",
      "presentButWeak": "Present but weak or irrelevant",
      "grabberMissing": "Grabber is missing",
      "background": "Background",
      "backgroundLevel4": "Includes mention of background or plot summary and situates the question in relevant context",
      "backgroundLevel3": "Includes mention of background of plot summary but context is underdeveloped",
      "backgroundLevel2": "Background or plot summary spotlights irrelevant details",
      "backgroundLevel1": "Missing or inaccurate",
      "restatementOfQuestion": "Restatement of Question",
      "restatementLevel4": "Restates the question while connecting it to the background",
      "restatementLevel3": "Restates the question without connecting it to background",
      "restatementlevel2": "Changes the meaning or only restates part of the question",
      "restatementLevel1": "Restatement of question is missing",
      "keyTermsDef": "Definition of Key Terms",
      "keyTermsLevel4": "Defines key terms in the question and weaves definition into the narrative",
      "keyTermsLevel3": "Defines key terms in the question",
      "keyTermsLevel2": "Defines unnecessary terms or defines terms unclearly",
      "keyTermsLevel1": "Definition of key terms missing",
      "thesis": "Thesis (Claim)",
      "thesisLevel4": "States a clear and analytical position that answers the question and is derived from the evidence",
      "thesisLevel3": "States a clear position that answers the question and is derived from the evidence",
      "thesisLevel2": "States a position but  incompletely or unclearly answers the question",
      "thesisLevel1": "Claim is off topic or missing",
      "roadMap": "Road Map",
      "roadMapLevel4": "Introduces insightful, analytical categories (buckets); may or may not be in the same sentence as thesis",
      "roadMapLevel3": "Introduces basic analytical categories (buckets); may or may not be in the same sentence as thesis",
      "roadMapLevel2": "Analytical categories (buckets) introduced but are insufficient either in number or clarity",
      "roadMapLevel1": "Categories (buckets) missing or incompatible with the question and/or evidence",
      "bodyParagraphs": "Body Paragraphs",
      "babyThesis": "Baby Thesis (Sub-Claim)",
      "babyThesisLevel4": "Strongly asserts a sub-claim that aligns with thesis and roadmap; may or may not be the first sentence in the paragraph",
      "babyThesisLevel3": "Clearly states a sub-claim that aligns with thesis and roadmap; may or not be the fist sentence in the paragraph",
      "babyThesisLevel2": "Sub-claim is indirectly stated and may stray from thesis and roadmap",
      "babyThesisLevel1": "Sub-claim is missing or is unrelated to the thesis and roadmap",
      "evidence": "Evidence",
      "evidenceLevel4a": "Purposefully selects and contextualizes quotations that illustrate baby thesis",
      "evidenceLevel4b": "Demonstrates a deep and nuanced understanding of the evidence",
      "evidenceLevel4c": "Uses all relevant documents",
      "evidenceLevel4d": "Includes parenthetical citations",
      "evidenceLevel3a": "Selects sufficient quotations that support the baby thesis",
      "evidenceLevel3b": "Demonstrates an understanding of the evidence",
      "evidenceLevel3c": "Uses most relevant documents",
      "evidenceLevel3d": "Includes parenthetical citations",
      "evidenceLevel2A": "Evidence present, but too thin, irrelevant, or inaccurately bucketed",
      "evidenceLevel2b": "Demonstrates an understanding of some, but not all of the evidence",
      "evidenceLevel2c": "Important documents ignored",
      "evidenceLevel2d": "Some citations missing or inaccurate",
      "evidenceLevel1a": "Concrete evidence and citations missing",
      "evidenceLevel1b": "Lacks an understanding of the majority of the evidence",
      "evidenceLevel1c": "Few, if any, documents used",
      "evidenceLevel1d": "No citations",
      "argument": "Argument (Reasoning)",
      "argumentLevel4a": "Clearly and perceptively explains how evidence supports the baby thesis",
      "argumentLevel4b": "Consistently shows deeper thinking in several ways:",
      "argumentLevel4b1": "Makes inferences",
      "argumentLevel4b2": "Considers context",
      "argumentLevel4b3": "Refers to narrative perspective, structure, and literary technique when appropriate",
      "argumentLevel4b4": "Tackles counter-arguments",
      "argumentLevel3a": "Clearly explains how evidence supports the baby thesis in a majority of the body paragraphs",
      "argumentLevel3b": "Shows some deeper thinking in at least one of the following ways:",
      "argumentLevel3b1": "Makes inferences",
      "argumentLevel3b2": "Considers context",
      "argumentLevel3b3": "Refers to narrative perspective, structure, and literary technique when appropriate",
      "argumentLevel3b4": "Tackles counter-arguments",
      "argumentLevel2a": "Partially explains how the evidence supports baby thesis, but more development is needed",
      "argumentLevel2b": "Demonstrates limited analytical thinking. Argument is unsupported by evidence and may not be present at all",
      "argumentLevel1a": "Fails to explain how the evidence supports the baby thesis; relies on evidence to \"speak for itself\"",
      "argumentLevel1b": "Negligible analytical thinking demonstrated",
      "conclusion": "Conclusion",
      "conclusionRestatement": "Restatement of Thesis and Main Arguments",
      "conclusionLevel4a": "Reasserts thesis and main arguments using fresh language",
      "conclusionLevel4b": "Presents counter- argument or thesis limitations when appropriate",
      "conclusionLevel3a": "Restates thesis and main arguments in language very similar to that used in the introduction",
      "conclusionLevel3b": "Acknowledges counter-argument or thesis limitations when appropriate",
      "conclusionLevel2a": "Unclearly restates thesis and main arguments and may introduce a new thesis",
      "conclusionLevel2b": "Fails to acknowledge counter-argument or thesis limitations",
      "conclusionLevel1a": "Thesis and main arguments are not restated",
      "conclusionLevel1b": "Concluding paragraph may not be present at all",
      "clincher": "Clincher",
      "clincherLevel4": "Persuasively suggests the real-world relevance of the question and/or challenges reader to think more deeply. May connect back to idea developed in the grabber",
      "clincherLevel3": "Provides a clear statement about the question’s importance",
      "clincherLevel2": "Cliché or superficial statement made about the question’s importance",
      "clincherLevel1": "Does not acknowledge the importance of essay question",
      "bigPicture": "Big Picture (Macrostructure)",
      "overallStructure": "Overall Structure",
      "structureLevel4a": "Essay includes an introduction, body paragraphs, and a conclusion",
      "structureLevel4b": "Body paragraphs correspond to categories identified in the road map",
      "structureLevel3a": "Essay includes an introduction, body paragraphs, and a conclusion",
      "structureLevel3b": "Body paragraphs correspond to categories identified in the road map",
      "structureLevel2a": "Essay includes an introduction, body paragraphs, and a conclusion",
      "structureLevel2b": "Body paragraphs missing from road map or are out of sequence",
      "structureLevel1": "Essay lacks identifiable parts",
      "focus": "Focus",
      "focusLevel4": "Has a seamless, logical flow that guides reader through the argument",
      "focusLevel3": "Focus maintained throughout paper",
      "focusLevel2": "May occasionally steer off-topic",
      "focusLevel1": "Essay lacks focus; paragraphing is absent or randomly applied",
      "transitions": "Transitions",
      "transitionsLevel4": "There are smooth transitions between paragraphs and ideas",
      "transitionsLevel3": "Transitional phrasing used, may be formulaic (e.g. First, Second, etc.)",
      "transitionsLevel2": "Transitions between paragraphs and ideas are disjointed or missing",
      "transitionsLevel1": "Organization is unclear; information is presented haphazardly",
      "mechanicsAndLanguage": "Mechanics and Language",
      "mechanics": "Mechanics",
      "mechanicsLevel4": "Practically free of errors in mechanics, grammar, spelling",
      "mechanicsLevel3": "Few errors in mechanics, grammar, and spelling",
      "mechanicsLevel2": "Several errors in mechanics, grammar, and spelling",
      "mechanicsLevel1": "Many errors in mechanics, grammar and spelling",
      "formality": "Formality",
      "formalityLevel4": "Maintains appropriate formality",
      "formalityLevel3": "Maintains appropriate formality",
      "formalityLevel2": "Formal tone inconsistently present",
      "formalityLevel1": "Uses informal tone",
      "languageUse": "Language Use",
      "languageUseLevel4": "Uses precise and varied language",
      "languageUseLevel3": "Some wordiness but mostly uses language efficiently",
      "languageUseLevel2": "Ideas are sometimes hard to understand because of poor word choice and/or sentence structure",
      "languageUseLevel1": "Ideas are hard to understand because of problems with word accuracy, sentence structure and mechanics"
    },
    "comprehensive": {
      "DBQProjectRubric": "Comprehensive DBQ Project Rubric",
      "advanced": "Advanced",
      "proficient": "Proficient",
      "emerging": "Emerging",
      "missing": "Not Yet or Missing",
      "intro": "Introduction",
      "grabber": "Grabber",
      "grabberLevel4": "Creative, eye-catching, relevant",
      "grabberLevel3": "Relevant, clear",
      "grabberLevel2": "Present but weak or irrelevant",
      "grabberLevel1": "Grabber is missing",
      "background": "Background",
      "backgroundLevel4": "Includes mention of time, place, and story and situates the question in relevant context",
      "backgroundLevel3": "Includes mention of time, place, and story but context is underdeveloped",
      "backgroundLevel2": "Time, place, OR story is missing, or spotlights irrelevant details",
      "backgroundLevel1": "Missing or inaccurate",
      "restatement": "Restatement of Question",
      "restatementLevel4": "Restates the question while connecting it to the background",
      "restatementLevel3": "Restates the question without connecting it to background",
      "restatementLevel2": "Changes the meaning or only restates part of the question",
      "restatementLevel1": "Restatement of question is missing",
      "keyTerms": "Definition of Key Terms",
      "keyTermsLevel4": "Defines key terms in the question and weaves definition into the narrative",
      "keyTermsLevel3": "Defines key terms in the question",
      "keyTermsLevel2": "Defines unnecessary terms or defines terms unclearly",
      "keyTermsLevel1": "Definition of key terms missing",
      "thesis": "Thesis (Claim)",
      "thesisLevel4": "States a clear and analytical position that answers the question and is derived from the evidence",
      "thesisLevel3": "States a clear position that answers the question and is derived from the evidence",
      "thesisLevel2": "States a position but incompletely or unclearly answers the question",
      "thesisLevel1": "Claim is off topic or missing",
      "roadMap": "Road Map",
      "roadMapLevel4": "Introduces insightful, analytical categories (buckets); may or may not be in the same sentence as thesis",
      "roadMapLevel3": "Introduces basic analytical categories (buckets); may or may not be in the same sentence as thesis",
      "roadMapLevel2": "Analytical categories (buckets) introduced but are insufficient either in number or clarity",
      "roadMapLevel1": "Categories (buckets) missing or incompatible with the question and/or evidence",
      "bodyParagraphs": "Body Paragraphs",
      "babyThesis": "Baby Thesis (Sub-Claim)",
      "babyThesisLevel4": "Strongly asserts a sub-claim that aligns with thesis and roadmap; may or may not be the first sentence in the paragraph",
      "babyThesisLevel3": "Clearly states a sub-claim that aligns with thesis and roadmap; may or not be the fist sentence in the paragraph",
      "babyThesisLevel2": "Sub-claim is indirectly stated and may stray from thesis and roadmap",
      "babyThesisLevel1": "Sub-claim is missing or is unrelated to the thesis and roadmap",
      "evidence": "Evidence",
      "evidenceLevel4a": "Purposefully selects significant concrete details that support baby thesis",
      "evidenceLevel4b": "Demonstrates a deep and nuanced understanding of the evidence",
      "evidenceLevel4c": "Uses all relevant documents",
      "evidenceLevel4d": "Includes parenthetical citations",
      "evidenceLevel3a": "Selects sufficient concrete details that support the baby thesis",
      "evidenceLevel3b": "Demonstrates an understanding of the evidence",
      "evidenceLevel3c": "Uses most relevant documents",
      "evidenceLevel3d": "Includes parenthetical citations",
      "evidenceLevel2a": "Evidence present, but too thin, irrelevant, or inaccurately bucketed",
      "evidenceLevel2b": "Demonstrates an understanding of some, but not all of the evidence",
      "evidenceLevel2c": "Important documents ignored",
      "evidenceLevel2d": "Some citations missing or inaccurate",
      "evidenceLevel1a": "Concrete evidence and citations missing",
      "evidenceLevel1b": "Lacks an understanding of the majority of the evidence",
      "evidenceLevel1c": "Few, if any, documents used",
      "evidenceLevel1d": "No citations",
      "argument": "Argument (Reasoning)",
      "argumentLevel4a": "Clearly and perceptively explains how evidence supports the baby thesis",
      "argumentLevel4b": "Consistently shows deeper thinking in several ways:",
      "argumentLevel4b1": "Makes inferences",
      "argumentLevel4b2": "Considers context",
      "argumentLevel4b3": "Cross-references documents for consistencies and/or inconsistencies",
      "argumentLevel4b4": "Considers point of view",
      "argumentLevel4b5": "Tackles counter-arguments",
      "argumentLevel3a": "Clearly explains how evidence supports the baby thesis in a majority of the body paragraphs",
      "argumentLevel3b": "Shows some deeper thinking in at least one of the following ways:",
      "argumentLevel3b1": "Makes inferences",
      "argumentLevel3b2": "Considers context",
      "argumentLevel3b3": "Cross references documents for consistencies and/or inconsistencies",
      "argumentLevel3b4": "Considers point of view",
      "argumentLevel3b5": "Tackles counter-arguments",
      "argumentLevel2a": "Partially explains how the evidence supports baby thesis, but more development is needed",
      "argumentLevel2b": "Demonstrates limited analytical thinking. Argument is unsupported by evidence and may not be present at all",
      "argumentLevel1a": "Fails to explain how the evidence supports the baby thesis; relies on evidence to \"speak for itself\"",
      "argumentLevel1b": "Negligible analytical thinking demonstrated",
      "conclusion": "Conclusion",
      "conclusionSubhead": "Restatement of Thesis and Main Arguments",
      "conclusionLevel4a": "Reasserts thesis and main arguments using fresh language",
      "conclusionLevel4b": "Presents counter- argument or thesis limitations when appropriate",
      "conclusionLevel3a": "Restates thesis and main arguments in language very similar to that used in the introduction",
      "conclusionLevel3b": "Acknowledges counter-argument or thesis limitations when appropriate",
      "conclusionLevel2a": "Unclearly restates thesis and main arguments and may introduce a new thesis",
      "conclusionLevel2b": "Fails to acknowledge counter-argument or thesis limitations",
      "conclusionLevel1a": "Thesis and main arguments are not restated",
      "conclusionLevel1b": "Concluding paragraph may not be present at all",
      "clincher": "Clincher",
      "clincherLevel4": "Persuasively suggests importance of topic and/or challenges reader to think more deeply. May connect back to idea developed in the grabber",
      "clincherLevel3": "Provides a clear statement about the topic's importance",
      "clincherLevel2": "Cliché or superficial statement made about the topic's importance",
      "clincherLevel1": "Does not acknowledge the importance of essay topic",
      "bigPicture": "Big Picture (Macrostructure)",
      "overallStructure": "Overall Structure",
      "structureLevel4a": "Essay includes an introduction, body paragraphs, and a conclusion",
      "structureLevel4b": "Body paragraphs correspond to categories identified in the road map",
      "structureLevel3a": "Essay includes an introduction, body paragraphs, and a conclusion",
      "structureLevel3b": "Body paragraphs correspond to categories identified in the road map",
      "structureLevel2a": "Essay includes an introduction, body paragraphs, and a conclusion",
      "structureLevel2b": "Body paragraphs missing from road map or are out of sequence",
      "structureLevel1": "Essay lacks identifiable parts",
      "focus": "Focus",
      "focusLevel4": "Has a seamless, logical flow that guides reader through the argument",
      "focusLevel3": "Focus maintained throughout paper",
      "focusLevel2": "May occasionally steer off-topic",
      "focusLevel1": "Essay lacks focus; paragraphing is absent or randomly applied",
      "transitions": "Transitions",
      "transitionsLevel4": "There are smooth transitions between paragraphs and ideas",
      "transitionsLevel3": "Transitional phrasing used, may be formulaic (e.g. First, Second, etc.)",
      "transitionsLevel2": "Transitions between paragraphs and ideas are disjointed or missing",
      "transitionsLevel1": "Organization is unclear; information is presented haphazardly",
      "mechanicsAndLanguage": "Mechanics and Language",
      "mechanics": "Mechanics",
      "mechanicsLevel4": "Practically free of errors in mechanics, grammar, spelling",
      "mechanicsLevel3": "Few errors in mechanics, grammar, and spelling",
      "mechanicsLevel2": "Several errors in mechanics, grammar, and spelling",
      "mechanicsLevel1": "Many errors in mechanics, grammar and spelling",
      "formality": "Formality",
      "formalityLevel4": "Maintains appropriate formality",
      "formalityLevel3": "Maintains appropriate formality",
      "formalityLevel2": "Formal tone inconsistently present",
      "formalityLevel1": "Uses informal tone",
      "language": "Language Use",
      "languageLevel4": "Uses precise and varied language",
      "languageLevel3": "Some wordiness but mostly uses language efficiently",
      "languageLevel2": "Ideas are sometimes hard to understand because of poor word choice and/or sentence structure",
      "languageLevel1": "Ideas are hard to understand because of problems with word accuracy, sentence structure and mechanics"
    },
    "holistic": {
      "rubricForDBQEssay": "Holistic Rubric for the DBQ Essay: Scoring Range 4-1",
      "rubricInstructions": "RUBRIC - INSTRUCTIONS",
      "rubricButtonInstructions": "Select the Rubric button to view the full rubric.",
      "essayLevel4": "The 4 Essay",
      "essayLevel4a": "features an introductory paragraph that includes a restatement of the question, a definition of terms (where appropriate), a thesis, and a road map that introduces the analytical categories to be developed",
      "essayLevel4b": "is clearly organized with body paragraphs arranged in the order of the road map",
      "essayLevel4c": "contains a clear baby thesis for each body paragraph",
      "essayLevel4d": "uses a reasonable number of the documents and includes document citation",
      "essayLevel4e": "provides evidence from the documents that supports the claim of the baby thesis",
      "essayLevel4f": "demonstrates analytical skills such as seeing close detail, synthesis, finding inconsistencies, questioning author bias, and making judgments",
      "essayLevel4g": "provides an argument or warrant in each body paragraph that addresses the baby thesis",
      "essayLevel4h": "concludes with a paragraph that clinches argument with a succinct summary and/or fresh wrinkle",
      "essayLevel4i": "is well-written with few or no errors in sentence structure, spelling, punctuation, and capitalization",
      "essayLevel3": "The 3 Essay",
      "essayLevel3a": "features an introductory paragraph that includes a restatement of the question, a definition of terms (where appropriate), a thesis, and a road map that introduces the analytical categories to be developed",
      "essayLevel3b": "is clearly organized with body paragraphs arranged in the order of the road map",
      "essayLevel3c": "contains a clear baby thesis for each body paragraph",
      "essayLevel3d": "incorporates a reasonable number of the documents but may not always include citation",
      "essayLevel3e": "accurately interprets most but not all documents cited",
      "essayLevel3f": "in most body paragraphs includes an argument that ties the evidence to the baby thesis",
      "essayLevel3g": "concludes with a paragraph that restates thesis and may summarize argument",
      "essayLevel3h": "is quite well-written but includes some errors in grammar and spelling",
      "essayLevel2": "The 2 Essay",
      "essayLevel2a": "addresses the question but lacks some focus",
      "essayLevel2b": "has a functional introductory paragraph containing a stated or recognizable thesis and a road map",
      "essayLevel2c": "has a recognizable organization with main ideas divided into paragraphs; body paragraphs may not be in the same order as the road map",
      "essayLevel2d": "contains reference to several documents but there could easily be more",
      "essayLevel2e": "contains some effort at document analysis",
      "essayLevel2f": "often lacks a warrant; does not clearly explain how evidence answers the question",
      "essayLevel2g": "has some sort of concluding paragraph. May be unnecessarily repetitive",
      "essayLevel2h": "is generally written with complete sentences, but contains too many errors in verb tense, agreement, punctuation, spelling, and capitalization",
      "essayLevel1": "The 1 Essay",
      "essayLevel1a": "is inadequate in addressing or identifying the question",
      "essayLevel1b": "is often lacking a clear thesis, stated or implied",
      "essayLevel1c": "has unclear organization. This may include no road map, or body paragraphs that do not follow in an order suggested by the road map",
      "essayLevel1d": "makes reference to few documents. Citations are missing. Documents are often misunderstood.",
      "essayLevel1e": "the warrant in each body paragraph is missing",
      "essayLevel1f": "contains serious errors in sentence structure and mechanics",
      "overallScore": "Overall Score:"
    },
    "scoringGuide": {
      "rubricScoringGuide": "Rubric/Scoring Guide",
      "basicCore": "Basic Core",
      "elementNotPresent": "0 = element not present",
      "elementPresent": "1 = element present",
      "selfScore": "Self-Score",
      "teacher": "Teacher",
      "intro": "Introduction",
      "utilizesHookGrabber": "Utilizes a hook/grabber",
      "backgroundKnowledge": "Incorporates background knowledge",
      "roadMap": "Has acceptable thesis with Road Map",
      "subTotal": "Sub-Total:",
      "studentRowTotal": "Sub-Total:",
      "teacherRowTotal": "Sub-Total:",
      "bodyParagraphs": "Body Paragraphs",
      "usesDocs": "Uses all or a majority of documents",
      "analyzesPOV": "Analyzes point of view or bias (if possible in DBQ)",
      "citations": "Document Reference citations (e.g. Doc A)",
      "addParagraph": "Add Paragraph",
      "removeParagraph": "Remove Paragraph",
      "bodyParagraphs2": "Body Paragraph",
      "relatesToThesis": "Relates claim statement to the thesis",
      "providesEvidence": "Provides evidence taken directly from documents",
      "makesInference": "Makes an inference and/or argument that connects the evidence with the thesis",
      "conclusion": "Conclusion",
      "restateThesis": "Restates the thesis",
      "clincher": "Adds a clincher and/or states significance",
      "conventions": "Conventions",
      "grammar": "Grammar, spelling & neatness",
      "expandedCore": "Expanded Core",
      "expand": "Expands beyond Basic Core. Students must achieve a perfect basic score before earning ANY expanded core points.",
      "clearThesis": "Has a clear, analytical and comprehensive thesis",
      "answersQuestionThoroughly": "Addresses all parts of the question thoroughly",
      "usesDocs2": "Uses all or almost all of the documents (DBQ)",
      "understandNuance": "Shows understanding of nuances in documents",
      "4orMoreDocs": "Analyzes point of view in four or more documents",
      "analyzesDocs": "Analyzes documents in other ways with additional groupings",
      "outsideInfo": "Brings in extensive and relevant \"outside\" historical information",
      "counterArg": "States a counter-argument (\"although\" statement)",
      "totalBasicCore": "Total Basic Core",
      "totalExpandedCore": "Total Expanded Core",
      "totalBasicExpandedCore": "Total Basic + Expanded Core",
      "total": "Total: "
    },
    "selfcheck": {
      "selfCheck": "Self Check",
      "s": "S",
      "t": "T",
      "sStudent": "S = Student",
      "tTeacher": "T = Teacher",
      "elements": "Elements",
      "advanced": "Advanced",
      "proficient": "Proficient",
      "emerging": "Emerging",
      "missing": "Missing",
      "introduction": "Introduction",
      "grabber": "Grabber",
      "background": "Background (time, place, story)",
      "restatement": "Restatement of the Question",
      "keyTerms": "Definition of Key Terms (if necessary)",
      "thesis": "Thesis (Claim) with Road Map",
      "addParagraph": "Add Paragraph",
      "removeParagraph": "Remove Paragraph",
      "bodyParagraph": "Body Paragraph",
      "babyThesis": "Baby Thesis",
      "evidence": "Evidence with citations",
      "argument": "Argument",
      "conclusion": "Conclusion",
      "clincher": "Clincher/significance",
      "restatement2": "Restatement of thesis and main arguments"
    }
  },
  "eb": {
    "introFieldgrabber": "Grabber",
    "introFieldbackground": "Background",
    "introFieldrestate": "Restate the question with key terms defined",
    "introFieldclaim": "Thesis/Claim and Road Map"
  },
  "dasheets": {
    "removeLast": "Remove Last",
    "addRow": "Add Row"
  },
  "rubrics": {
    "commentLabel": "Comments",
    "commentPlaceholder": "click to leave a comment...",
    "saved": "Rubric Saved"
  },
  "swTextEditor": {
    "note": "Please note:",
    "warnNoSend": "You may not 'Send To Teacher' until you receive comments on your previously submitted version.",
    "understand": "Thanks, I understand.",
    "warnOverwrite": "Do you want to overwrite your current Essay text with the Paragraphs from your Outline?",
    "cancle": "Cancel",
    "yes": "Yes, use the paragraphs from my outline!",
    "textEditPlaceholder": "Compose an epic...",
    "teacherReviewTitle": "Save and Submit",
    "teacherReview": "This will save your essay and submit it to your teacher for review.",
    "close": "Cancel"
  }
};
